import { call, put, takeLatest } from 'redux-saga/effects'
import {
  UPDATE_DEVICE_COUNTS,
  UPDATE_DEVICE_COUNTS_SUCCESS,
  UPDATE_DEVICE_COUNTS_FAILURE,
} from 'redux/constants/DeviceCounts'
import { fetchDeviceCounts } from '../../firebase/fireStore/devicesDBMethods'

function* fetchDeviceCountsSaga(action) {
  try {
    const data = yield call(fetchDeviceCounts, action.tenantId)
    yield put({
      type: UPDATE_DEVICE_COUNTS_SUCCESS,
      deviceCounts: { ...data },
      lastFetched: Date.now(),
    })
  } catch (error) {
    console.error('fetchDeviceCounts err', error)
    yield put({ type: UPDATE_DEVICE_COUNTS_FAILURE, error })
  }
}

export default function* deviceCountsSaga() {
  yield takeLatest(UPDATE_DEVICE_COUNTS, fetchDeviceCountsSaga)
}
