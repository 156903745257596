import { DATABASE, DEFAULT_SYSTEM_ROLES } from '../../constants/Constant'
import { getDocument, getMultipleDocument } from './firebaseCliDaoMethods'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { orderBy } from 'firebase/firestore'

const getRoleConfig = async (tenantId) => {
  const colDocPath = `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.TENANT_CONFIG_COL}/${DATABASE.ROLE_CONFIG}/${DATABASE.ROLE_CONFIG}`
  return new Promise(function (resolve, reject) {
    let conditions = [orderBy('createdDateTime')]
    getMultipleDocument(colDocPath, conditions)
      .then((snapshot) => {
        let roleData = {}
        let defaultRoles = {}
        let roleNames = []
        DEFAULT_SYSTEM_ROLES.forEach((role) => {
          defaultRoles[role] = {}
        })

        for (let configData of snapshot.docs) {
          let data = configData.data()

          if (DEFAULT_SYSTEM_ROLES.includes(data.roleName)) {
            defaultRoles[data.roleName] = {
              ...data.permissions,
              role: {
                roleDescription: data.roleDescription,
                roleName: data.roleName,
                roleDocId: configData.id,
                roleId: data && data.roleId ? data.roleId : '',
                createdDateTime: data.createdDateTime,
              },
            }
          } else {
            roleNames.push({ roleName: data.roleName, createdDateTime: data.createdDateTime })
            roleData[data.roleName] = {
              ...data.permissions,
              role: {
                roleDescription: data.roleDescription,
                roleName: data.roleName,
                roleDocId: configData.id,
                roleId: data && data.roleId ? data.roleId : '',
                createdDateTime: data.createdDateTime,
              },
            }
          }
        }
        const sortedRoleNames = roleNames
          .sort((a, b) => a.createdDateTime - b.createdDateTime)
          .reduce((acc, item) => {
            acc.push(item.roleName)
            return acc
          }, [...DEFAULT_SYSTEM_ROLES])
        resolve({ configs: { ...defaultRoles, ...roleData }, roleNames: sortedRoleNames })
      })
      .catch((err) => {
        console.log('Error while fetching roleConfig, Error: ' + JSON.stringify(err))
        reject(err)
      })
  })
}

const getPermissions = async (tenantId, uId) => {
  let colPath = `${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.USERS}/${uId}/${DATABASE.USER_PERMISSIONS}/`

  return new Promise(function (resolve, reject) {
    getDocument(colPath, 'permissionList')
      .then((snapshot) => {
        resolve(snapshot.data() ? snapshot.data() : [])
      })
      .catch((err) => {
        console.log('Error while getPermission, Error: ' + JSON.stringify(err))
        resolve([])
      })
  })
}

async function updateRolePermissionConfigs(tenantId, payload) {
  return new Promise(function (resolve) {
    let updateRolePermissions = httpsCallable(getFunctions(), 'updateRolePermissions')

    return updateRolePermissions({ userId: tenantId, payload })
      .then((res) => {
        console.log(
          'got response from updateRolePermissionConfigs, Res: ',
          JSON.stringify(res.data)
        )
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in updateRolePermissionConfigs : ' + err)
        resolve(err)
      })
  })
}

async function addRoleWrapper(tenantId, roleName, roleDetails) {
  return new Promise(function (resolve) {
    let addCustomRole = httpsCallable(getFunctions(), 'addCustomRole')

    return addCustomRole({ userId: tenantId, roleName: roleName, roleDetails: roleDetails })
      .then((res) => {
        console.log('got response from addCustomRole, Res: ', JSON.stringify(res.data))
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in addCustomRole : ' + err)
        resolve(err)
      })
  })
}

export { getRoleConfig, updateRolePermissionConfigs, addRoleWrapper, getPermissions }
