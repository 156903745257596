import React, { useEffect, useState } from 'react'
import { getTenantId, getRole, getEmailId } from './FirebaseAuth'
import { AuthContext } from './AuthProvider'
import {
  dismissGoogleWarningBanner,
  getGoogleDriveData,
} from '../firebase/fireStore/thirdPartyAppsDBMethods'
import { Spin, message } from 'antd'
import './Auth.css'

export const GoogleDriveContext = React.createContext()

export const GoogleDriveProvider = ({ children }) => {
  const context = React.useContext(AuthContext)
  const tenantId = getTenantId(context?.currentUser)
  const tenantEmail = getEmailId(context?.currentUser)
  const role = getRole(context?.currentUser)
  const [gDriveData, setGDriveData] = useState(null)
  const [pending, setPending] = useState(true)
  const [disableGDWarningPending, setDisableGDWarningPending] = useState(false)

  useEffect(() => {
    getData()
  }, [context])

  useEffect(() => {
    let expriTime = gDriveData?.expiryTime - 120000
    let timeInMilisec = expriTime < Date.now() ? 0 : expriTime - Date.now()
    if (timeInMilisec > 0) {
      let timer = setTimeout(() => {
        console.log('gDriveData timer called ')
        getData()
        clearTimeout(timer)
      }, timeInMilisec)
    }
  }, [gDriveData?.expiryTime])

  const getData = () => {
    let userType = role === 'Administrative User' ? 1 : 0
    getGoogleDriveData(tenantId, userType)
      .then((gdData) => {
        //console.log("gdData",gdData.result)
        setGDriveData(gdData.result)
        setPending(false)
      })
      .catch((err) => {
        console.log('GoogleDriveProvider err ==', err)
        setGDriveData(null)
        setPending(false)
      })
  }

  if (pending) {
    return (
      <>
        <Spin size="large" tip="Loading" />
      </>
    )
  }

  const hideGoogleWarningBanner = () => {
    setDisableGDWarningPending(true)
    dismissGoogleWarningBanner(tenantId).then((res) => {
      console.log('dismissGoogleWarningBanner response :', res.data)
      if (res.success === 'failed') {
        message.error(res.message)
      } else {
        let data = { ...gDriveData }
        data.hideWarningBanner = true
        setGDriveData(data)
      }
      setDisableGDWarningPending(false)
    })
  }

  return (
    <GoogleDriveContext.Provider
      value={{
        googleSignInAccesstoken: gDriveData?.accessToken,
        googleSignInExpiryTime: gDriveData?.expiryTime,
        googleSignInEmail: gDriveData?.email,
        googlePrevUserId: gDriveData?.prevUserId,
        hideWarningBanner: gDriveData && gDriveData.hideWarningBanner ? true : false,
        hideGoogleWarningBanner: hideGoogleWarningBanner,
        disableGDWarningPending: disableGDWarningPending,
        getData: getData,
        isConnectedToZebraServices: gDriveData?.isConnectedToZebraServices,
        zebraServiceAccountEmail: gDriveData?.zebraServiceAccountEmail
          ? gDriveData?.zebraServiceAccountEmail
          : tenantEmail,
        hidePopup: gDriveData?.hidePopup,
        enrollmentTokenAvailable: gDriveData?.enrollmentTokenAvailable,
        isZebraAuthExpired: gDriveData?.isZebraAuthExpired,
      }}
    >
      {children}
    </GoogleDriveContext.Provider>
  )
}
