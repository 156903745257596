import React, { useEffect, useLayoutEffect, useState, useCallback } from 'react'
import { Layout, Grid } from 'antd'
import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import SideNav from 'components/layout-components/SideNav'
import TopNav from 'components/layout-components/TopNav'
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav'
import PageHeader from 'components/layout-components/PageHeader'
import Footer from 'components/layout-components/Footer'
import navigationConfig from 'configs/NavigationConfig'
import { NAV_TYPE_TOP } from 'constants/ThemeConstant'
import utils from 'utils'
import { useGetTenantId } from 'hooks/useGetTenantId'

const { Content } = Layout

export const CustomLayout = ({ navType, location, views }) => {

  const tenantId = useGetTenantId()
  //localStorage.setItem('tenantId', tenantId)
  const currentRouteInfo = utils.getRouteInfo(navigationConfig('/app', tenantId), location.pathname)
  const isNavTop = navType === NAV_TYPE_TOP

  return (
    <Layout>
      <HeaderNav />
      <TopNav routeInfo={currentRouteInfo} />
      <Layout className="app-container">
        <SideNav routeInfo={currentRouteInfo} />
        <Layout className="app-layout">
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <Switch>
                <Route path="" component={views} />
              </Switch>
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      <MobileNav />
    </Layout>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navType, locale } = theme
  return { navType, locale }
}

export default withRouter(connect(mapStateToProps)(CustomLayout))
