import { SIDE_NAV_LIGHT, SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant'
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Zebra DNA Cloud™'
export const API_BASE_URL = env.API_ENDPOINT_URL

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '#091E42',
  mobileNav: false,
  isMobileWindow: false,
  windowPaddingLeft: 0,
}
