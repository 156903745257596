import { DATABASE } from '../../constants/Constant'
import { getDocument, setDocument } from './firebaseCliDaoMethods'

function getTableColumnsDBDocPath(tenantId, userId, tableName) {
  return `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.USERS}/${userId}/${DATABASE.USER_PREFERENCE}/${DATABASE.PAGE_PREFERENCE}/${tableName}`
}

const fetchTableColumns = (params) => {
  let colPath = getTableColumnsDBDocPath(params.tenantId, params.userId, params.tableName)
  return new Promise((resolve) => {
    getDocument(colPath, DATABASE.TABLE_PREFERENCE)
      .then((docRef) => {
        let data = []
        if (docRef.exists()) {
          let doc = docRef.data()
          if (Object.keys(doc).length && doc.column) {
            let colKeys = Object.keys(doc.column)
            if (colKeys.length) {
              data = colKeys.reduce((acc, key) => {
                if (doc.column[key]) acc.push(key)
                return acc
              }, [])
            }
          }
        }
        resolve(data)
      })
      .catch((e) => {
        console.log('Error while fetching table columns list error msg:', e)
        resolve([])
      })
  })
}

const updateSelectedColumnsInDB = (params) => {
  const colPath = getTableColumnsDBDocPath(params.tenantId, params.userId, params.tableName)
  const data = params.selectedCols.reduce((acc, key) => {
    acc[key] = true
    return acc
  }, {})
  return new Promise((resolve) => {
    setDocument(colPath, DATABASE.TABLE_PREFERENCE, { column: data }).then((res) =>{
      resolve(params.selectedCols)
    }).catch((e)=>{
      console.log('Error while updating columns data in DB:', e)
      resolve([])
    })
  })
}

export { fetchTableColumns, updateSelectedColumnsInDB }