import { combineReducers } from 'redux'
import Auth from './Auth'
import Theme from './Theme'
import TableColumns from './tables/TableColumns'
import DeviceCounts from './DeviceCounts'
import KeyboardBuilder from './KeyboardBuilder'

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  tableColumns: TableColumns,
  deviceCounts: DeviceCounts,
  keyboardBuilder: KeyboardBuilder,
})

export default reducers
