import React, { useContext } from 'react'
import { Menu, Dropdown, Avatar, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { LogoutOutlined, UserOutlined, UsergroupAddOutlined, LockOutlined } from '@ant-design/icons'
import Icon from 'components/util-components/Icon'
import { signOut } from 'redux/actions/Auth'
import { DISABLED_FEATURE_MESSAGE } from 'constants/Constant'
import {
  getEmailId,
  getFirebaseUserName,
  getLoginType,
  getRole,
  getFriendlyName,
  getUserPermission,
  getCustomRole,
} from 'auth/FirebaseAuth'
import { AuthContext } from 'auth/AuthProvider'
import { TENANT_PERMISSION_TYPE } from 'redux/constants/Auth'
import { ReactComponent as myServicesmenu } from '../../assets/images/my_servicesmenu.svg'
import { ReactComponent as rolesSvg } from '../../assets/images/roles_1.svg'

const menuItems = [
  {
    title: 'My Profile',
    icon: UserOutlined,
    path: '/user/myProfile',
    key: 'myProfile',
  },
  {
    title: 'My Services',
    icon: myServicesmenu,
    path: '/user/myServices',
    key: 'myServices',
  },
  {
    title: 'Roles',
    icon: rolesSvg,
    path: '/user/roleManagement',
    key: 'roleManagement',
  },
  {
    title: 'Users',
    icon: UsergroupAddOutlined,
    path: '/user/userManagement',
    key: 'userManagement',
  },
]

export const NavProfile = ({ signOut, _location }) => {
  const context = useContext(AuthContext)
  const profileImg = '/img/avatars/thumb-1.png'
  const mailId = getEmailId(context.currentUser)
  const userName = getFirebaseUserName(context.currentUser)
  const friendlyId = getFriendlyName(context.currentUser)
  const userEmail =
    mailId && mailId != 'undefined'
      ? !mailId.includes('@')
        ? mailId + '@zebra.com'
        : mailId
      : 'NA'
  const styles = {
    avatar: {
      backgroundColor: '#FFAB00',
      height: '40px',
      width: '40px',
      borderRadius: '1.5em',
      padding: 5,
      marginRight: 12,
    },
  }
  const userPermission = getUserPermission(context?.currentUser)

  const noAccessPermissions =
    userPermission && userPermission.permissions
      ? userPermission.permissions.filter((permission) =>
          permission.permissionString.includes('noaccess')
        )
      : []
  const MenuItem = ({ item, noAcessPermission }) => (
    <Menu.Item key={item.key} className="" disabled={noAcessPermission}>
      <Link to={item.path} className="float-left">
        <span className="display-flex align-items-center">
          <Icon type={item.icon} className="interim-color mr-3" />
          <span className="font-weight-normal">{item.title}</span>
        </span>
      </Link>
      {noAcessPermission && (
        <Tooltip placement="topRight" title={DISABLED_FEATURE_MESSAGE.NOACCESS_PERMISSION_TOOLTIP}>
          <LockOutlined
            style={{ color: '#C1C7D0', float: 'right', lineHeight: '1rem', display: 'block' }}
          />
        </Tooltip>
      )}
    </Menu.Item>
  )

  const menuItemList = menuItems.map((item) => {
    let noAcessPermission = noAccessPermissions.some((permission) =>
      permission.permissionString.includes(item.key)
    )
    if (item.path.includes('roleManagement')) {
      return getRole(context.currentUser) === TENANT_PERMISSION_TYPE ||
        getCustomRole(context.currentUser) === 'Admin' ? (
        <MenuItem item={item} key={item.key} noAcessPermission={noAcessPermission} />
      ) : null
    }

    return <MenuItem item={item} key={item.key} noAcessPermission={noAcessPermission} />
  })

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <Avatar src={profileImg} className="nav-profile-thumb" />
        <p className="nav-profile-name">{userName}</p>
        <span className="nav-profile-mail">{userEmail}</span>
        <p className="nav-profile-mail">Company ID : {friendlyId}</p>
      </div>
      <div className="">
        <Menu>
          {menuItemList}
          <Menu.Item key={'signOut'} onClick={(_e) => signOut(getLoginType(context?.currentUser))}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )
  return (
    <div className="full-width display-flex justify-flex-end">
      <Dropdown
        placement="bottomRight"
        className="profile-dropdown fw-b5 mr-3"
        overlay={profileMenu}
        trigger={['click']}
      >
        <span>
          <Avatar src={profileImg} style={styles.avatar} /> {userName}
        </span>
      </Dropdown>
    </div>
  )
}

export default withRouter(connect(null, { signOut })(NavProfile))
