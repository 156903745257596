export const SIGNIN = 'SIGNIN'
export const AUTHENTICATED = 'AUTHENTICATED'
export const SIGNOUT = 'SIGNOUT'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'
export const AUTH_TOKEN = 'valat'
export const TENANT_ID = 'valti'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_CUSTOM_TOKEN = 'SIGNIN_WITH_CUSTOM_TOKEN'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'
export const FIREBASE_USER_TENANT_ID = 'valfati'
export const HIDE_POPUP_INITIALSETUP = 'hide_popup_initialSetup'
export const HIDE_POPUP_VIEWBARCODE = 'hide_popup_viewbarcode'
export const HIDE_POPUP_NEWSETTING = 'hide_popup_newSetting'
export const HIDE_POPUP_APPLYSETTING = 'hide_popup_applySetting'
export const EMAIL_ID = 'valei'
export const ROLE = 'valr'
export const ADMIN_PERMISSION_TYPE = 'Administrative User'
export const TENANT_PERMISSION_TYPE = 'Tenant Owner'
export const TENANT_CREATED_TIME = 'valtct'
export const FIREBASE_USERNAME = 'valfun'
export const TOGGLE_STATE = 'valts'
export const TOGGLE_VALUE = 'valtv'
export const LOGIN_TYPE = 'vallt'
export const SHOW_POPUP = 'valsp'
export const FETCH_USER_PERMISSION_FIREBASE = 'valur'