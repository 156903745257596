
export const extractValueByDot = (key, initial) => {
  const keys = key.split('.')

  let result = initial

  for (const element of keys) {
    if (!result) break
    result = result[element]
  }

  return result || ''
}
