import {
  TOGGLE_COLLAPSED_NAV,
  CHANGE_LOCALE,
  SIDE_NAV_STYLE_CHANGE,
  NAV_TYPE_CHANGE,
  TOP_NAV_COLOR_CHANGE,
  HEADER_NAV_COLOR_CHANGE,
  TOGGLE_MOBILE_NAV,
  IS_MOBILE_WINDOW_CHANGE,
} from '../constants/Theme'
import { THEME_CONFIG } from 'configs/AppConfig'

const initTheme = { ...THEME_CONFIG }

const theme = (state = initTheme, action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        ...action.data,
      }
    case SIDE_NAV_STYLE_CHANGE:
      return {
        ...state,
        sideNavTheme: action.sideNavTheme,
      }
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale,
      }
    case NAV_TYPE_CHANGE:
      return {
        ...state,
        ...action.data,
      }
    case TOP_NAV_COLOR_CHANGE:
      return {
        ...state,
        topNavColor: action.topNavColor,
      }
    case HEADER_NAV_COLOR_CHANGE:
      return {
        ...state,
        headerNavColor: action.headerNavColor,
      }
    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: action.mobileNav,
      }
    case IS_MOBILE_WINDOW_CHANGE:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}

export default theme
