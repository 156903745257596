import { db } from 'auth/FirebaseAuth'
import { where, writeBatch, orderBy, doc } from "firebase/firestore"
import { DATABASE } from '../../constants/Constant'
import { getAndroidVersion } from '../../utils/device-helpers'
//const dbCollectionDevices = `/${DATABASE.TENANT_COL}/${getTenantId()}/${DATABASE.DEVICES}`
import { getDocument, getMultipleDocument } from './firebaseCliDaoMethods'

function getDBCollectionDevices(tenantId) {
  return `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.DEVICES}`
}

const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

async function addGroupsInDevices(devices, group, tenantId) {
  const batchSize = 500 // Firestore allows up to 500 operations per batch
  const delayTime = 2000 // 2 second delay

  const dbCollectionDevices = getDBCollectionDevices(tenantId)

  for (let i = 0; i < devices.length; i += batchSize) {
    const batch = writeBatch(db)
    const chunk = devices.slice(i, i + batchSize)
    
    chunk.forEach((res) => {
      let thingsRef = doc(db, dbCollectionDevices, res.id)
      batch.update(thingsRef, { groups: group, groups_search: group.length > 0 ? group[0].toUpperCase() : [] })
    })

    // Commit the batch
    try {
      await batch.commit()
      console.log(`Batch from ${i} to ${i + batchSize} committed successfully`)
    } catch (error) {
      console.error('Error writing batch: ', error)
      return { status: 'error', message: error }
    }

    // Delay between batches
    if (i + batchSize < devices.length) {
      await delay(delayTime)
    }
  }

  return { status: 'success', message: 'Device groups successfully added in devices' }
}

const getDeviceListByGroup = async (groupName, tenantId) => {
  return new Promise((resolve, reject) => {
    let docData = []
    let conditions = []
    let dbCollectionDevices = getDBCollectionDevices(tenantId)

    if (groupName !== 'Select a device group') {
      conditions.push(where('groups', 'array-contains', groupName))
    }
    conditions.push(orderBy('createdDateTime', 'desc'))
    getMultipleDocument(dbCollectionDevices, conditions)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.exists) {
            let obj = doc.data()
            obj = getAndroidVersion(obj)
            docData.push({ id: doc.id, ...obj })
          }
        })
        resolve(docData)
      })
      .catch((e) => {
        console.log('Get device list by group error msg:', e)
      })
  })
}

const getDeviceListByGroupInDeviceSetting = async (groupName, tenantId) => {
  return new Promise((resolve, reject) => {
    let docData = []
    let dbCollectionDevices = getDBCollectionDevices(tenantId)
    let conditions = []

    if (groupName.length <= 30) {
      if (groupName.length) {
        conditions.push(where('groups', 'array-contains-any', groupName))
      }
      conditions.push(orderBy('createdDateTime', 'desc'))
      getMultipleDocument(dbCollectionDevices, conditions)
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc.exists) {
              let obj = doc.data()
              obj = getAndroidVersion(obj)
              docData.push({ id: doc.id, ...obj })
            }
          })
          resolve(docData)
        })
        .catch((e) => {
          console.log('Get device list by group error msg:', e)
        })
    }
  })
}

const getDeviceListByFilters = async (tenantId, filters = {}) => {
  return new Promise((resolve, reject) => {
    let docData = []
    let conditions = []
    let dbCollectionDevices = getDBCollectionDevices(tenantId)

    if (filters) {
      let keys = Object.keys(filters)
      keys.forEach((key) => {
        let lowercaseKey =
          key.toLowerCase() === 'securitypatch' ? 'securityPatch' : key.toLowerCase()
        let condition = lowercaseKey == 'groups' ? 'array-contains' : '=='
        let val = lowercaseKey === 'groups' ? filters[key] : filters[key].toUpperCase()
        console.log(`Adding key ${key}:${filters[key]} to query`)
        conditions.push(where(lowercaseKey, condition, val))
      })
    } 
    conditions.push(orderBy('createdDateTime', 'desc'))
    getMultipleDocument(dbCollectionDevices, conditions)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.exists) {
            let obj = doc.data()
            obj = getAndroidVersion(obj)
            docData.push({ id: doc.id, ...obj })
          }
        })
        resolve(docData)
      })
      .catch((e) => {
        console.log('Get device list by group error msg:', e)
      })
  })
}

const getFotaDeviceListByFilters = async (tenantId, filters = {}) => {
  return new Promise((resolve, reject) => {
    let docData = []
    let dbCollectionDevices = getDBCollectionDevices(tenantId)
    let conditions = []
    conditions.push(where('fotaEnabled', '==', true))

    if (filters) {
      let keys = Object.keys(filters)
      keys.forEach((key) => {
        let lowercaseKey = key.toLowerCase()
        let condition = lowercaseKey == 'groups' ? 'array-contains' : '=='
        let val = lowercaseKey === 'groups' ? filters[key] : filters[key].toUpperCase()
        console.log(`Adding key ${key}:${filters[key]} to query`)
        conditions.push(where(lowercaseKey, condition, val))
      })
    }

    getMultipleDocument(dbCollectionDevices, conditions)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.exists) {
            let obj = doc.data()
            obj = getAndroidVersion(obj)
            docData.push({ id: doc.id, ...obj })
          }
        })
        resolve(docData)
      })
      .catch((e) => {
        console.log('Get device list by group error msg:', e)
      })
  })
}

const getDeviceListById = async (id, tenantId) => {
  return new Promise((resolve, reject) => {
    let docData = {}
    let dbCollectionDevices = getDBCollectionDevices(tenantId)

    getDocument(dbCollectionDevices, id)
      .then((snapshot) => {
        if (snapshot.exists) {
          let obj = snapshot.data()
          obj = getAndroidVersion(obj)
          docData = { id: snapshot.id, ...obj }
        }
        resolve(docData)
      })
      .catch((e) => {
        console.log('Get device by id error msg:', e)
        reject(e)
      })
  })
}

const fetchDeviceAppsList = (deviceId, tenantId) => {
  return new Promise((resolve) => {
    let dbCollectionDevices = getDBCollectionDevices(tenantId)
    let dbCollectionDeviceApps = `${dbCollectionDevices}/${deviceId}/${DATABASE.DEVICE_APPS_ISTALLED}`
    let appsList = []
    let conditions = []
    conditions.push(orderBy('createdDateTime', 'desc'))

    getMultipleDocument(dbCollectionDeviceApps, conditions)
      .then((snapshot) => {
        snapshot.forEach((app) => {
          if (app.exists) {
            appsList.push({ ...app.data(), id: app.id })
          }
        })
        resolve(appsList)
      })
      .catch((err) => {
        console.log('Get device apps list error msg:', err)
        resolve([])
      })
  })
}

const fetchBatteryStatusDetails = (tenantId) => {
  let colPath = `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.DASHBOARD}`
  return new Promise((resolve) => {
    getDocument(colPath, DATABASE.BATTERY_HEALTH_STATUS)
      .then((docRef) => {
        let data = {}
        if (docRef.exists) {
          data = docRef.data()
        }
        resolve(data)
      })
      .catch((e) => {
        console.log('Get device by id error msg:', e)
        resolve({})
      })
  })
}

const fetchDeviceCounts = (tenantId) => {
  let colPath = `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.DASHBOARD}`
  return new Promise((resolve) => {
    getDocument(colPath, DATABASE.DEVICE_COUNTS)
      .then((docRef) => {
        let data = {}
        if (docRef.exists) {
          data = docRef.data()
        }
        resolve(data)
      })
      .catch((e) => {
        console.log('Get device counts by tenant id error msg:', e)
        resolve({})
      })
  })
}

export {
  addGroupsInDevices,
  getDeviceListByGroup,
  getDeviceListByFilters,
  getFotaDeviceListByFilters,
  getDeviceListById,
  fetchDeviceAppsList,
  getDeviceListByGroupInDeviceSetting,
  fetchBatteryStatusDetails,
  fetchDeviceCounts,
}