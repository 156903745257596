import React, { useEffect, useState } from 'react'
import { auth, onAuthStateChanged } from './FirebaseAuth'
import { getDocsFromAuthentication } from '../firebase/cloudFunction/index'
import { getPermissions } from '../firebase/fireStore/roleManagementDBMethods'
import {
  TENANT_PERMISSION_TYPE,
  ADMIN_PERMISSION_TYPE,
  FIREBASE_USERNAME,
  TENANT_CREATED_TIME,
  LOGIN_TYPE,
  FETCH_USER_PERMISSION_FIREBASE,
} from '../redux/constants/Auth'
import { Spin } from 'antd'
import './Auth.css'
import axios from 'axios'
import { getDocumentUsingDocPath } from '../../src/firebase/fireStore/firebaseCliDaoMethods'


const getWiPermission = async (tenantId) => {
  const collectionPath = `/tenants/${tenantId}/tenantConfig/WirelessInsightConfig`;
  // const collectionPath = `/tenants/${tenantId}/tenantConfig/test`;
  console.log("Looking for:", collectionPath);
  try {
    const snapshot = await getDocumentUsingDocPath(collectionPath);
    return snapshot._document ? true : false;
  } catch (error) {
    console.error('Error occurred:', error);
    throw error; 
  }
};

export const AuthContext = React.createContext()
export const useAuthContext = () => React.useContext(AuthContext)
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [pending, setPending] = useState(true)

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        //console.log('User - ' + JSON.stringify(user))
        await setCustomClaims(user)
        setPending(false)
      }

      setCurrentUser(user)
      setPending(false)
    })
  }, [])

  async function setCustomClaims(user) {

    let idTokenResult = await user.getIdTokenResult()
    let wiPermissionResult
    try {
      wiPermissionResult = await getWiPermission(idTokenResult.claims.tenantId)
    } catch (error) {
      wiPermissionResult = false
    }

    console.log("ID Token:", wiPermissionResult)
    user.claims = idTokenResult.claims
    user.claims['wiPermission'] = wiPermissionResult
    user.claims['role'] =
      user.claims.role === TENANT_PERMISSION_TYPE ? ADMIN_PERMISSION_TYPE : TENANT_PERMISSION_TYPE
    if (user.claims && user.claims.tenantId) {
      let tenantID = user.claims.tenantId
      let uuid
      if (user.claims.role === ADMIN_PERMISSION_TYPE) {
        uuid = tenantID
        tenantID = user.claims.identity
      } else {
        uuid = tenantID
      }
      let userPermissions = await getPermissions(tenantID, uuid)
      if (userPermissions?.permissions?.length) {
        user.claims['userPermissions'] = userPermissions
      } else {
        user.claims['userPermissions'] = { permissions: [] }
      }
    }
    let loginType = user.claims.loginType ? user.claims.loginType : '1'
    if (localStorage.getItem(LOGIN_TYPE)) {
      loginType = localStorage.getItem(LOGIN_TYPE)
    }

    console.log('LOGIN TYPE VALUS IS ', loginType)
    if (user.claims.role === ADMIN_PERMISSION_TYPE && loginType == '1') {
      let getPerFir = ''
      let doc
      let getUserPermissionFlag = localStorage.getItem(FETCH_USER_PERMISSION_FIREBASE)
      if (getUserPermissionFlag && getUserPermissionFlag === '1') {
        getPerFir = getUserPermissionFlag
        doc = await getDocsFromAuthentication(user.uid, getPerFir)
      } else if (!getUserPermissionFlag) {
        localStorage.setItem(FETCH_USER_PERMISSION_FIREBASE, '1')
        doc = await getDocsFromAuthentication(user.uid, '')
      }
      // let doc = await getDocsFromAuthentication(user.uid, getPerFir)
      user.claims[FIREBASE_USERNAME] = doc.data.userName
      user.claims['enrollmentTokenAvailable'] = doc.data.enrollmentTokenAvailable
      user.claims['user_id'] = doc.data.adminTenantId
      user.claims[TENANT_CREATED_TIME] = doc.data.createdDateTime
      user.claims['loginType'] = '1'
      user.claims['friendlyId'] = doc.data.friendlyCompanyId
      user.claims['userPermissions'] = { permissions: doc.data.permissions }
      user.claims['customRoleName'] = doc.data.customRoleName

    } else if (user.claims.role === ADMIN_PERMISSION_TYPE && loginType == '2') {
      user.claims['user_id'] = user.claims.identity
    }
  }

  if (pending) {
    return (
      <>
        <Spin size="large" tip="Loading" />
      </>
    )
  }

  return <AuthContext.Provider value={{ currentUser }}>{children}</AuthContext.Provider>
}
