export const NEXT_STEP = 'NEXT_STEP'
export const PREVIOUS_STEP = 'PREVIOUS_STEP'
export const RESET = 'RESET'
export const SET_LAYOUT_VALUES = 'SET_LAYOUT_VALUES'
export const SET_LAYOUT_CURRENT_INDEX = 'SET_LAYOUT_CURRENT_INDEX'
export const ADD_LAYOUT = 'ADD_LAYOUT'
export const REMOVE_LAYOUT = 'REMOVE_LAYOUT'
export const SET_KEYBOARD_VALUES = 'SET_KEYBOARD_VALUES'
export const SET_LAYOUTS = 'SET_LAYOUTS'
export const SET_STATUS_BAR = 'SET_STATUS_BAR'
export const SET_SYSTEM_BAR = 'SET_SYSTEM_BAR'
export const SET_KEY_SELECTED = 'SET_KEY_SELECTED'
export const SET_ID = 'SET_ID'
export const RESET_STEP = 'RESET_STEP'
export const SET_KEY_VALUES = 'SET_KEY_VALUES'
export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE'
export const SET_GENERAL_KEY = 'SET_GENERAL_KEY'
export const SET_DEVICE_VALUES = 'SET_DEVICE_VALUES'
export const SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL'
export const SET_KEY_BUTTON = 'SET_KEY_BUTTON'
export const SET_ACTION_KEY_BUTTON = 'SET_ACTION_KEY_BUTTON'
export const SET_KEYBOARD = 'SET_KEYBOARD'
export const SET_LAYOUT_PROPERTIES = 'SET_LAYOUT_PROPERTIES'
export const SET_KEY_ACTIONS_VALUES = 'SET_KEY_ACTIONS_VALUES'
export const SET_IS_EDITOR_MODE = 'SET_IS_EDITOR_MODE'
export const ADD_ROW_KEYBOARD = 'ADD_ROW_KEYBOARD'
export const SET_ROW_WIDTH = 'SET_ROW_WIDTH'
export const SET_ROW_WIDTH_BALANCE = 'SET_ROW_WIDTH_BALANCE'
export const DELETE_ROW_KEY_BUTTON = 'DELETE_ROW_KEY_BUTTON'
export const APPLY_TO_ALL_KEYS = 'APPLY_TO_ALL_KEYS'
export const APPLY_TO_ALL_LAYOUTS = 'APPLY_TO_ALL_LAYOUTS'
export const APPLY_TO_ALL_KEYS_IN_LAYOUTS = 'APPLY_TO_ALL_KEYS_IN_LAYOUTS'
export const SET_TRANSPARENCY = 'SET_TRANSPARENCY'
export const UPDATE_LAYOUT_PROPERTIES = 'UPDATE_LAYOUT_PROPERTIES'
export const SET_BACKGROUND_COLOR_LAYOUT_PROPERTIES = 'SET_BACKGROUND_COLOR_LAYOUT_PROPERTIES'
export const SET_POSITION_LAYOUT_PROPERTIES = 'SET_POSITION_LAYOUT_PROPERTIES'
export const SET_PREVIEWKEY_PROPERTIES = 'SET_PREVIEWKEY_PROPERTIES'
export const SET_MARGIN_SPACING_LAYOUT_PROPERTIES = 'SET_MARGIN_SPACING_LAYOUT_PROPERTIES'
export const SET_HEIGHT_MANUAL_LAYOUT_PROPERTIES = 'SET_HEIGHT_MANUAL_LAYOUT_PROPERTIES'
export const SET_WIDTH_MANUAL_LAYOUT_PROPERTIES = 'SET_WIDTH_MANUAL_LAYOUT_PROPERTIES'
export const SET_HEIGHT_STANDARD_LAYOUT_PROPERTIES = 'SET_HEIGHT_STANDARD_LAYOUT_PROPERTIES'
export const SET_WIDTH_STANDARD_LAYOUT_PROPERTIES = 'SET_WIDTH_STANDARD_LAYOUT_PROPERTIES'
export const SET_STYLING_KEY_VALUES = 'SET_STYLING_KEY_VALUES'
export const SET_BACKGROUND_COLOR_KEY_VALUE = 'SET_BACKGROUND_COLOR_KEY_VALUE'
export const SET_HAPTIC_FEEDBACK_KEY_VALUES = 'SET_HAPTIC_FEEDBACK_KEY_VALUES'
export const SET_ACTIONS_KEY_VALUES = 'SET_ACTIONS_KEY_VALUES'
export const SET_CONTENT_KEY_VALUES = 'SET_CONTENT_KEY_VALUES'
export const SET_DEPLOY_KEYBOARD_VALUES = 'SET_DEPLOY_KEYBOARD_VALUES'
