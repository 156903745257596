import React, { lazy, Suspense, useContext, useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AuthContext } from 'auth/AuthProvider'
import { getTenantId, getRole, getUserPermission, getCustomRole } from 'auth/FirebaseAuth'
import { TENANT_PERMISSION_TYPE } from 'redux/constants/Auth'
import Loading from 'components/shared-components/Loading'
import './styles.css'

export const UserViews = ({ match }) => {
  const context = useContext(AuthContext)
  const [role] = useState(getRole(context?.currentUser))

  const userPermission = getUserPermission(context?.currentUser)
  const noAccessPermissions =
    userPermission && userPermission.permissions
      ? userPermission.permissions.filter((permission) =>
          permission.permissionString.includes('noaccess')
        )
      : []
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {!noAccessPermissions.some((permission) =>
          permission.permissionString.includes('myProfile')
        ) ? (
          <Route path={`${match.url}/myProfile`} component={lazy(() => import(`./myProfile`))} />
        ) : null}

        {!noAccessPermissions.some((permission) =>
          permission.permissionString.includes('myServices')
        ) ? (
          <Route path={`${match.url}/myServices`} component={lazy(() => import(`./myServices`))} />
        ) : null}

        {!noAccessPermissions.some((permission) =>
          permission.permissionString.includes('userManagement')
        ) ? (
          <Route
            path={`${match.url}/userManagement`}
            component={lazy(() => import(`./userManagement`))}
          />
        ) : null}
        {role === TENANT_PERMISSION_TYPE || getCustomRole(context?.currentUser) === 'Admin' ? (
          <>
            {!noAccessPermissions.some((permission) =>
              permission.permissionString.includes('roleManagement')
            ) ? (
              <Route
                path={`${match.url}/roleManagement`}
                component={lazy(() => import(`./roleManagement`))}
              />
            ) : null}
          </>
        ) : null}
        {!noAccessPermissions.some((permission) =>
          permission.permissionString.includes('myProfile')
        ) ? (
          <Redirect from={`${match.url}`} to={`${match.url}/myProfile`} />
        ) : null}
      </Switch>
    </Suspense>
  )
}

export default UserViews
