export const screenSize = {
  mobile: {
    // 9:16
    width: 270,
    height: 480,
    systemBarHeight: 24,
    statusBarHeight: 12,
  },
  tablet: {
    // 10:16
    width: 300,
    height: 480,
    systemBarHeight: 24,
    statusBarHeight: 12,
  },
  wearable: {
    // 3:5
    width: 400,
    height: 280,
    systemBarHeight: 24,
    statusBarHeight: 18,
  },
  vehicleMount: {
    // 10:16
    width: 300,
    height: 480,
    systemBarHeight: null,
    statusBarHeight: null,
  },
  vc8300_10: {
    //4:3
    width: 524,
    height: 393,
    systemBarHeight: 80,
    statusBarHeight: 48,
  },
  vc8300_8: {
    //16:9
    width: 480,
    height: 270,
    systemBarHeight: 48,
    statusBarHeight: 28,
  },
}

export default {
  screenSize,
}
