import { DIRECTION_DICTIONARY, LANDSCAPE, LAYOUT_TAB_HEIGHT } from 'constants/EnterpriseKeyboardBuilderConstant'
import { max, min, uniqueId } from 'lodash'

const getUid = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length)
    result += chars[randomIndex]
  }
  return `${Date.now()}-${result}`
}

const getRowId = () => uniqueId(`r-${getUid()}-`)

const getKeyId = () => uniqueId(`k-${getUid()}-`)

export const getLayoutId = () => uniqueId(`l-${getUid()}-`)

export const generateKeyButtons = (rows, columns, width, keyPreset = {}) => {
  keyPreset.width = width / columns
  return Array.from({ length: rows }, () => generateKeyRow(columns, keyPreset))
}

export const generateKeyRow = (columns, keyPreset = {}) => {
  return {
    id: getRowId(),
    columns: Array.from({ length: columns }, () => generateKey(keyPreset)),
  }
}

export const generateKey = (keyPreset = {}) => {
  return { ...keyPreset, id: getKeyId() }
}

export const getDimensionByOrientation = (orientation, width, height) => {
  const biggerSide = max([width, height])
  const smallerSide = min([width, height])

  if (orientation === LANDSCAPE) {
    return { width: biggerSide, height: smallerSide }
  }
  return { width: smallerSide, height: biggerSide }
}

export const copyButtons = (buttons) => {
  return buttons.map((row) => {
    return {
      id: getRowId(),
      columns: row.columns.map((key) => {
        return { ...key, id: getKeyId() }
      }),
    }
  })
}

export const getLayoutTopLeft = ({
  keyboard,
  layoutIndex,
  isEditorMode,
  layoutWidth,
  layoutHeight,
}) => {
  const {
    screen: { width, height, statusBarHeight, systemBarHeight },
    devicePreview: { statusBar, systemBar },
    layouts,
  } = keyboard

  const layout = layouts[layoutIndex]
  const dimensions = getDimensionByOrientation(layout.properties.orientation, width, height)
  const systemBarOn = isEditorMode && systemBar ? systemBarHeight : 0
  const statusBarOn = isEditorMode && statusBar ? statusBarHeight : 0
  const usableScreenHeight = dimensions.height - (systemBarOn + statusBarOn)
  const [topDirection, leftDirection] = layout.properties.direction.split('-')
  const top = (usableScreenHeight - layoutHeight) * DIRECTION_DICTIONARY[topDirection]
  const left = (dimensions.width - layoutWidth) * DIRECTION_DICTIONARY[leftDirection]

  return { top, left ,statusBar,systemBar}
}

export const getRowHeight = ({
  height,
  layoutGap,
  keyGap,
  rows,
  layoutLength,
}) => {
  const usableHeight = height - layoutGap * 2 + keyGap
  const tabHeight = layoutLength > 1 ? LAYOUT_TAB_HEIGHT : 0
  return (usableHeight - tabHeight) / rows - keyGap
}
