import { DATABASE, LICENSE_SOURCE, zDNA_FEATURE } from '../../constants/Constant'
import { licenseWrapper } from '../cloudFunction/index'
import moment from 'moment'
import { getDocument, getMultipleDocument } from './firebaseCliDaoMethods'
import { where } from 'firebase/firestore'

function parseLicenseDataOnFeatures(licenses) {
  const features = {}

  for (const license of licenses) {
    const featureName = license.featureName

    if (features[featureName]) {
      features[featureName].usedCount += license.usedCount
      features[featureName].claimCountService += license.claimCountService
    } else {
      features[featureName] = {
        productName: license.productName,
        featureName: license.featureName,
        usedCount: license.usedCount,
        claimCountService: license.claimCountService,
      }
    }
  }

  return features
}

const fetchLicenseDetails = (tenantId) => {
  return new Promise(function (resolve, reject) {
    licenseWrapper(tenantId, LICENSE_SOURCE.GET_LICENSES)
      .then((res) => {
        if (res.status === 'success') {
          let activeLicenses = []
          let now = new Date()
          let dateString = moment(now).format('YYYY-MM-DD')
          for (const license of res.data.data) {
            if (license.status === 1 && dateString <= license.expiry) {
              // dateString> license.start
              activeLicenses.push(license)
            }
          }
          resolve(parseLicenseDataOnFeatures(activeLicenses))
        } else {
          resolve({ status: false })
        }
      })
      .catch((err) => {
        console.log('Error while fetching license details: Error: ' + err)
        resolve({ status: false })
      })
  })
}

const getSupportedModels = () => {
  const dbSupportedModels = `/${DATABASE.CLIENT_CONFIG_COL}/`
  return new Promise(function (resolve, reject) {
    getDocument(dbSupportedModels, DATABASE.SUPPORTED_MODELS)
      .then((snapshot) => {
        console.log('Supported Models fetched from DB: ' + JSON.stringify(snapshot.data()))
        resolve(snapshot.data().models ? snapshot.data().models : [])
      })
      .catch((err) => {
        resolve([])
        console.log('Error while fetching supported models')
      })
  })
}

const getAllocationPreference = (tenantId, featureName) => {
  const allocatePreferenceRef = `${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.TENANT_CONFIG_COL}`
  let lincenseDocPath = ''
  if (featureName === zDNA_FEATURE) {
    lincenseDocPath = DATABASE.LICENSE_CONFIG
  } else {
    lincenseDocPath = featureName
  }

  return new Promise(function (resolve, reject) {
    getDocument(allocatePreferenceRef, lincenseDocPath)
      .then((snapshot) => {
        console.log('Fetched allocation preference from DB: ' + JSON.stringify(snapshot.data()))
        resolve(snapshot.data())
      })
      .catch((err) => {
        console.log('Error while fetching allocation preference')
      })
  })
}

const getLicensedDevices = (tenantId, featureName) => {
  const deviceColRef = `${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.DEVICES}`
  return new Promise((resolve) => {
    let queryArr = []
    if (featureName === zDNA_FEATURE) {
      queryArr.push(where('mdnaLicense', '==', true))
    } else {
      queryArr.push(where(`licenses.${featureName}`, '==', '1'))
    }
    let deviceKeys = []
    let devices = []
    getMultipleDocument(deviceColRef, queryArr)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.exists) {
            let obj = doc.data()
            obj.id = doc.id
            deviceKeys.push(doc.id)
            devices.push(obj)
          }
        })
        resolve({ devices, deviceKeys })
      })
      .catch((e) => {
        console.error('getMultipleDocument error ::', e)
        resolve({ devices, deviceKeys })
      })
  })
}

export {
  fetchLicenseDetails,
  getSupportedModels,
  getAllocationPreference,
  parseLicenseDataOnFeatures,
  getLicensedDevices,
}
